import Module from 'lib/module';
import Util from 'lib/util';

@Module.define('banner-tiles')
class BannerTiles extends Module {

    constructor(options) {
        super(options);

        this.bannerTileMain = $('.js-banner-tile-main');
        this.thumbnail = $('.js-banner-tile-thumbs li');
    }

    main() {
    }

    bindEvents() {
        this.thumbnail.on('click touch', e => this.tileSwitch(e));
    }

    tileSwitch(e) {
        // Set variables for dynamic items we need to grab
        let $this = $(e.currentTarget);
        let mainImage = $this.data('image-main');

        // If tile is already active, bail
        if (!$this.hasClass('active')) {
            // Switch active class
            this.thumbnail.removeClass('active');
            $this.addClass('active');

            // Swtich out image
            this.bannerTileMain.find('.image').css('background-image', 'url("' + mainImage + '")');
            // Switch out copy
            this.bannerTileMain.find('.description').replaceWith($this.find('.description').clone());
        }
    }
}